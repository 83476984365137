



























































































import { Component, Vue } from 'vue-property-decorator'
import get from 'lodash.get'

@Component
export default class DemoIframe extends Vue {
  portrait?: Window
  landscape?: Window
  portraitValue = 0
  autoplay = false
  controls = false
  seekBar = false
  episodeId = ''
  miniSeekBar = false

  get portraitSeekBarValue() {
    return this.portraitValue
  }
  set portraitSeekBarValue(value) {
    this.portraitValue = Math.ceil(value)
  }

  created() {
    this.episodeId = get(this.$route.params, 'episodeId', '')
    this.autoplay = get(this.$route.query, 'autoplay', 0) === '1'
    this.controls = get(this.$route.query, 'controls', '1') === '1'
    this.seekBar = get(this.$route.query, 'seekBar', 0) === '1'
    this.miniSeekBar = get(this.$route.query, 'miniSeekBar', 0) === '1'
  }

  get url() {
    let url = window.location.origin
    url = this.episodeId ? `${url}/${this.episodeId}?` : ''
    url = this.autoplay ? `${url}autoplay=1&` : `${url}autoplay=0&`
    url = this.controls ? `${url}controls=1&` : `${url}controls=0&`
    url = this.seekBar ? `${url}seekBar=1` : `${url}seekBar=0&`
    url = this.miniSeekBar ? `${url}miniSeekBar=1` : `${url}miniSeekBar=0`

    return url
  }

  portraitLoad() {
    window.addEventListener('message', (e) => {
      if (e.origin === 'http://localhost:8080' && e.data.context === 'adori-web-player') {
        // console.log(e.data)
      }
    })
    // @ts-ignore
    this.portrait = this.$refs.portrait.contentWindow
  }

  handlePortraitPlay() {
    this.portrait &&
      this.portrait.postMessage(
        {
          context: 'adori-web-player',
          eventName: 'play',
        },
        '*'
      )
  }
  handlePortraitPause() {
    this.portrait &&
      this.portrait.postMessage(
        {
          context: 'adori-web-player',
          eventName: 'pause',
        },
        '*'
      )
  }
  handlePortraitSeekBarChange() {
    this.portrait &&
      this.portrait.postMessage(
        {
          context: 'adori-web-player',
          eventName: 'seekTo',
          info: {
            seekTo: this.portraitSeekBarValue,
          },
        },
        '*'
      )
  }
  landscapeLoad() {
    window.addEventListener('message', (e) => {
      if (e.origin === 'http://localhost:8080' && e.data.context === 'adori-web-player') {
        // console.log(e.data)
      }
    })
    // @ts-ignore
    this.landscape = this.$refs.landscape.contentWindow
  }
  handleLandscapePlay() {
    this.landscape &&
      this.landscape.postMessage(
        {
          context: 'adori-web-player',
          eventName: 'play',
        },
        '*'
      )
  }
  handleLandscapePause() {
    this.landscape &&
      this.landscape.postMessage(
        {
          context: 'adori-web-player',
          eventName: 'pause',
        },
        '*'
      )
  }
  handleLandscapeSeekBarChange() {
    this.landscape &&
      this.landscape.postMessage(
        {
          context: 'adori-web-player',
          eventName: 'seekTo',
          info: {
            seekTo: this.portraitSeekBarValue,
          },
        },
        '*'
      )
  }
}
